import { useEffect, useState, Fragment } from "react";
import { Route, Routes } from "react-router";
import Nav from "./Layout/navbar";
import Footer from "./Layout/footer";
import HomeCarousel from "./Layout/home-carousel";
import AboutUs from "./Layout/about-us";
import Process from "./Layout/process";
import Blog from "./Layout/blog";
import ContactUs from "./Layout/contact-us";
import CreateResume from "./Layout/create-resume";
import NewEditor from "./Components/new-resume-builder/NewEditor";
// import Download from "./Components/new-resume-builder/Download";
import SentPage from "./Components/pages/SentPage";
import Feature from "./Components/Features/Feature";
import ServiceBox from "./Components/service/service";
import TemplateSelectionPage from "./Components/new-resume-builder/TemplateSelectionPage";

// Import images
import resume1 from "./images/resume1.jpeg";
import resume2 from "./images/resume2.jpg";
import resume3 from "./images/resume3.jpg";

function App() {
  const [loading, setLoading] = useState(true);
  const [navbarDark, setNavbarDark] = useState(true);
  const [resumeId, setResumeId] = useState(true);

  // Simulate loading for 2 seconds
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  // Carousel data
  const carouselData = [
    {
      key: 1,
      heading:
        "'98% of Fortune 500 companies use Applicant Tracking System (ATS) to filter resumes.'",
      headingBy: "Job scan",
      image: resume1,
      imgName: "resume1-img",
    },
    {
      key: 2,
      heading:
        "'Hiring Manager spends an average of only 7.4 seconds reviewing a resume'",
      headingBy: "Forbes",
      image: resume2,
      imgName: "resume2-img",
    },
    {
      key: 3,
      heading:
        "'On an average, 75% of resumes are rejected by Application Tracking System (ATS) before ever reaching the hands of hiring managers.'",
      headingBy: "Forbes",
      image: resume3,
      imgName: "resume3-img",
    },
  ];

  return (
    <div className="App font-['Open_Sans'] relative">
      <Nav navbarDark={navbarDark} className="pb-5" />

      <Routes>
        <Route
          path="/"
          element={
            <Fragment>
              <HomeCarousel data={carouselData} setNavbarDark={setNavbarDark} />
            </Fragment>
          }
        />
        <Route path="/home" element={<HomeCarousel />} />
        <Route path="/services" element={<ServiceBox />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/process" element={<Process />} />
        <Route path="/create-resume" element={<CreateResume />} />
        <Route path="/features" element={<Feature />} />
        <Route path="/blogs" element={<Blog />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/templateselection" element={<TemplateSelectionPage />} />
        <Route path="/build-resume" element={<NewEditor set={setResumeId} />} />
        {/* <Route path="/download" element={<Download id={resumeId} />} /> */}
        <Route path="/success" element={<SentPage />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
