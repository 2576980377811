
import { useState, useEffect } from "react";
import resume1 from "../images/resume1.jpeg";
import resume2 from "../images/resume2.jpg";
import resume3 from "../images/resume3.jpg";
import Testimonials from "./testimonials";
import ServiceBox from "../Components/service/service";
import AboutUs from "./about-us";
import Guide from "../Components/guide/Guide";
import Templates from "./templates";
import ResumeShortlistedBy from "./resume-shortlisted-by";
import Createcover from "../Components/createcover/Createcover";
import Cvex from "../Components/CvEx/Cvex";
import Feature from "../Components/Features/Feature";
import ResumeEx from "../Components/ResumeExample/ResumeEx";
import Process from "./process";
import Faq from "../Components/faq/Faq";
import Join from "../Components/Join/Join";
import ContactUs from "./contact-us";
import { Link } from "react-router-dom";
import "../index.css";
import AOS from "aos";
import "aos/dist/aos.css";
const backgroundImageUrls = [resume1, resume2, resume3];
const HomeCarousel = () => {
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBackgroundIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImageUrls.length
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  const backgroundImageUrl = backgroundImageUrls[backgroundIndex];

  const carouselData = [
    {
      key: 1,
      heading:
        "'98% of Fortune 500 companies use Applicant Tracking System (ATS) to filter resumes.'",
      headingBy: "Job scan",
      image: resume1,
      imgName: "resume1-img",
    },
    {
      key: 2,
      heading:
        "'Hiring Manager spends an average of only 7.4 seconds reviewing a resume'",
      headingBy: "Forbes",
      image: resume2,
      imgName: "resume2-img",
    },
    {
      key: 3,
      heading:
        "'On an average, 75% of resumes are rejected by Application Tracking System (ATS) before ever- reaching the hands of hiring managers.'",
      headingBy: "Forbes",
      image: resume3,
      imgName: "resume3-img",
    },
  ];

  const styles = {
    height: "100vh",
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "all 700ms ease-in-out",
    zIndex: "-1",
    marginTop: "57px",
  };

  return (
    <>
      <div style={styles} id="home">
        <div
          className="text-black "
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 1s",
            backgroundColor: "#eff6ffc9",
            // zIndex: "10",
          }}
        >
          <p className="pt-5 text-center text-md" data-aos="fade-down">
            ONLINE RESUME BUILDER
          </p>
          <h2
            data-aos="zoom-in"
            className={
              "italic text-2xl lg:text-4xl text-black-500 font-serif font-bold pt-2 head"
            }
            style={{
              fontSize: "2.0rem",
              marginBottom: "1rem",
              zIndex: "11",
              justifyContent: "center",
              width: "80%",
              transition: "all 1s ease-in-out",
            }}
          >
            {carouselData[backgroundIndex].heading}
          </h2>
          <p className="text-lg text-center ">
            ~ {carouselData[backgroundIndex].headingBy}
          </p>
          <p className="py-2 text-lg text-center" data-aos="zoom-in">
            Build a Job winning Perfect Resume & CV in just 5 minutes{" "}
          </p>
          <button className="p-3 text-white bluebg">
            <Link to="/build-resume" className="text-white">
              Create My Resume
            </Link>
          </button>
          <p className="pt-3 pb-2 text-lg text-center" data-aos="zoom-in">
            30,037 resumes created today
          </p>
        </div>
      </div>
      <Testimonials />
      <ServiceBox />
      <AboutUs />
      <Guide />
      <Templates />
      <ResumeShortlistedBy />
      <Createcover />
      <Cvex />
      <Feature />
      <ResumeEx />
      <Process />
      <Faq />
      <Join />
      <ContactUs />
    </>
  );
};

export default HomeCarousel;
