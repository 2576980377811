import React, { useState, useEffect } from "react";
import Button from "../InputControl/Button";
import TemplateBlock from "./factory-components/blocks/TemplateBlock";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import template1 from "./templates/template1.jpg";
import template2 from "./templates/template2.jpg";
import template3 from "./templates/template3.jpg";
import template4 from "./templates/template4.jpg";
import loader from "./loader.gif";
const templates = [template1, template2, template3, template4];
export default function TemplateSelectionPage(props) {
  const [template, setTemplate] = useState();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(true);
  const [showPayment, setShowPayment] = useState(false);
  const [id, setId] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (showPayment) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.setAttribute("data-payment_button_id", "pl_Pso7UKaNv1EDKy");
      const form = document.getElementById("razorpay-form");
      if (form) {
        form.appendChild(script);
      }
    }
  }, [showPayment]);
  const handleSubmit = async () => {
    if (![1, 2, 3, 4].includes(template)) {
      setCheck(false);
      return;
    }
    setCheck(true);
    setShowPayment(true); // Show Razorpay button when user clicks submit
  };
  // Handle Resume Generation after Payment
  const handlePaymentSuccess = async () => {
    try {
      setLoading(true);
      // :one: Generate Resume
      const res = await axios.post(
        "https://www.resumebyte.com/api/createResume",
        {
          ...props.submit,
          template,
        }
      );
      props.set(res.data.data.id);
      setId(res.data.data.id);
      // :two: Send Email
      await sendEmailDirectly(res.data.data.id);
    } catch (error) {
      console.log("Error during Resume Generation or Email:", error);
    } finally {
      setLoading(false);
    }
  };
  const sendEmailDirectly = async (resumeId) => {
    try {
      await axios.post("https://www.resumebyte.com/api/sendEmail", {
        id: resumeId,
      });
      alert("Payment successful, resume sent!");
      navigate("/home"); // Redirect to home after payment & email
    } catch (error) {
      console.log("Error while sending email:", error);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex flex-col items-center justify-center gap-2">
          <div>
            <img src={loader} alt="loader" />
          </div>
          <div className="text-md">Processing Payment, Please Wait...</div>
        </div>
      ) : (
        <div
          className={`w-full h-full flex flex-col justify-center gap-y-4 relative`}
        >
          <div className="text-[40px] w-full text-center">
            1 & 2 - Non ATS | Choose a Template | 3 & 4 ATS Compatible
          </div>
          <div className="flex flex-col flex-wrap items-center w-full md:flex-row justify-evenly gap-y-4 ">
            {templates.map((tem, i) => (
              <div
                className={`w-full md:w-[30%] rounded-lg ${
                  i + 1 === template ? "border-[3px] border-green-400" : ""
                }`}
                key={i}
                onClick={() => setTemplate(i + 1)}
              >
                <TemplateBlock image={tem}></TemplateBlock>
              </div>
            ))}
          </div>
          <div className="flex justify-between w-full gap-x-8">
            <div
              className="flex gap-x-8 w-[100px]"
              onClick={() => props.setcomp(5)}
            >
              <Button text="Back" />
            </div>
            <div className="flex flex-col gap-x-8 " onClick={handleSubmit}>
              <Button text="Generate Resume" />
              <div
                className={`text-red-500 ${
                  !check ? "block" : "hidden"
                } text-[12px]`}
              >
                Please select a template
              </div>
            </div>
          </div>
          {showPayment && (
            <form
              id="razorpay-form"
              className="border border-black absolute top-0 left-0 w-full h-full flex justify-center items-center bg-black/50 z-10"
            >
              <script
                src="https://checkout.razorpay.com/v1/payment-button.js"
                data-payment_button_id="pl_Pso7UKaNv1EDKy"
                async
                onLoad={handlePaymentSuccess} // Resume & Email sent after payment
              ></script>
            </form>
          )}
        </div>
      )}
      {/* Razorpay Payment Button (Hidden Initially) */}
    </div>
  );
}
